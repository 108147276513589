<template>
  <b-modal
    id="upload-background-image"
    :visible="showModalUploadBackgroundImage"
    :title="$attrs.title"
    footer-class="d-flex justify-content-between"
    size="lg"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:show-modal-upload-background-image', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ $attrs.title }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          right
          :disabled="busy"
          @click="(busy === false) ? onDiscard() : false"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <div
      class="p-0 m-0"
      style="padding: 0px; margin: 0px;"
    >
      <b-card
        style="padding: 0px; margin: 0px;"
      >
        <div
          style="border: 1px solid #eeeeee; padding: 20px 20px 20px 20px;"
        >
          <b-form-file
            ref="refFileImage"
            v-model="fileImage"
            browse-text="+"
            class="mt-1"
            :placeholder="`${$t('Choose a file or drop it here')}...`"
            :drop-placeholder="`${$t('Drop file here')}...`"
            accept="image/png,image/jpg,image/jpeg"
            @change="changeFile"
          />

          <b-card-text
            class="my-1"
            style="font-size: 14px;"
          >
            {{ $t('Selected file') }}: <strong>{{ fileImage ? fileImage.name : '' }}</strong>
          </b-card-text>
        </div>
        <div style="padding-top: 10px;">
          <span style="color: #999999; font-size: 12px;">
            รูปแบบไฟล์: JPG, JPEG, PNG<br>
            ขนาดไฟล์: ไม่เกิน 1 MB<br>
            ขนาดรูป: 2500×1686 พิกเซล, 2500×843 พิกเซล, 1200×810 พิกเซล, 1200×405 พิกเซล, 800×540 พิกเซล, 800×270 พิกเซล<br>
          </span>
        </div>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BFormFile,
  BCardText,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import store from '@/store'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BFormFile,
    BCardText,
    BCard,

    FeatherIcon,
  },
  model: {
    prop: 'showModalUploadBackgroundImage',
    event: 'update:show-modal-upload-background-image',
  },
  props: {
    showModalUploadBackgroundImage: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fileImage: null,
      required,
      busy: false,
      skin: store.state.appConfig.layout.skin,
    }
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onDiscard() {
      this.$emit('discard-upload-background-image')
      this.$emit('update:show-modal-upload-background-image', false)
    },
    changeFile(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0]
        const fileSize = (file.size / 1024 / 1024).toFixed(2)
        if (fileSize <= 1) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            const image = new Image()
            image.src = e.target.result

            image.onload = () => {
              const { width } = image
              const { height } = image

              if ((width === 2500 && height === 1686)
                 || (width === 2500 && height === 843)
                 || (width === 2500 && height === 810)
                 || (width === 1200 && height === 405)
                 || (width === 800 && height === 540)
                 || (width === 800 && height === 270)) {
                this.$emit('update-upload-background-image', image)
                this.$emit('update-upload-background-file', file)
                this.$emit('update:show-modal-upload-background-image', false)
                return true
              }
              this.$refs.refFileImage.reset()
              const errorMsg = `${this.$i18n.t('Please upload an image of the required size')}`
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              })
              return false
            }
          }
        } else {
          this.$refs.refFileImage.reset()
          const errorMsg = `${this.$i18n.t('File size exceeds')} 1 MB`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorMsg,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          })
        }
      } else {
        // please select
        this.$refs.refFileImage.reset()
        const errorMsg = this.$i18n.t('Please select file')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorMsg,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Browse2';
  }
}
</style>
