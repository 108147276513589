<template>
  <b-modal
    id="form-select-template"
    :visible="showModalSelectTemplate"
    :title="$attrs.title"
    footer-class="d-flex justify-content-between"
    size="lg"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:show-modal-select-template', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ $attrs.title }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ $t('Select') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <div>
      <b-form
        ref="refForm"
        class="position-relative"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <div>
          <b-card style="margin-bottom: 0.6rem;">
            <div>
              <b-row>
                <b-col
                  v-for="(template, index) in templateRichMenu"
                  :key="index"
                  cols="3"
                >
                  <b-link @click="selectTemplate(template.name)">
                    <b-img
                      class="p-0 m-1"
                      thumbnail
                      :src="require(`@/assets/images/template_richmenu/${template.name}${(templateSelect === template.name)? '_select' : ''}.png`)"
                      alt="Image 1"
                    />
                  </b-link>
                </b-col>
              </b-row>

            </div>
          </b-card>
        </div>

      </b-form>

    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BForm,
  BRow,
  BCol,
  BCard,
  BImg,
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import store from '@/store'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
    BImg,
    BLink,

    FeatherIcon,
  },
  model: {
    prop: 'showModalSelectTemplate',
    event: 'update:show-modal-select-template',
  },
  props: {
    showModalSelectTemplate: {
      type: Boolean,
      required: true,
    },
    templateRichMenu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      templateSelect: '',
      required,
      busy: false,
      skin: store.state.appConfig.layout.skin,
    }
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.busy = true
      if (this.templateSelect !== '') {
        this.$emit('update-select-template', this.templateSelect)
        this.$emit('update:show-modal-select-template', false)
        this.busy = false
      } else {
        const errorMsg = this.$i18n.t('Please Select Template')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorMsg,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
        this.busy = false
      }
    },
    onDiscard() {
      this.$emit('discard-select-template')
      this.$emit('update:show-modal-select-template', false)
    },
    selectTemplate(val) {
      this.templateSelect = val
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
