<template>
  <div>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        ref="refForm"
        class="position-relative"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-card class="p-0 m-0">
          <b-card-title class="mb-1">
            {{ $t('Setting Rich Menu') }}
          </b-card-title>
          <b-row>

            <!-- Field: Name -->
            <b-col
              cols="3"
            >
              <b-form-group
                :label="$t('Name')"
                label-for="header-name"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Name')"
                  rules="required"
                >
                  <b-form-input
                    id="header-name"
                    v-model="headerData.name"
                    :state="getValidationState(validationContext)"
                    maxlength="30"
                    :disabled="busy"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: type -->
            <b-col
              cols="2"
            >
              <b-form-group
                :label="`${$t('Rich Menu Type')}`"
                label-for="header-display-menu"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Rich Menu Type')"
                  rules="required"
                >
                  <b-form-radio
                    v-model="headerData.type"
                    name="header-type"
                    value="default"
                    class="custom-control-info cursor-pointer"
                    inline
                  >
                    <span class="cursor-pointer">{{ $t('Default') }}</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="headerData.type"
                    name="header-type"
                    value="customer"
                    class="custom-control-info cursor-pointer"
                    inline
                  >
                    <span class="cursor-pointer">{{ $t('Customer') }}</span>
                  </b-form-radio>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: Chat Bar Title -->
            <b-col
              cols="2"
            >
              <b-form-group
                :label="$t('Chat Bar Title')"
                label-for="header-chat-bar-title"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Chat Bar Title')"
                  rules="required"
                >
                  <b-form-input
                    id="header-chat-bar-title"
                    v-model="headerData.chatBarTitle"
                    :state="getValidationState(validationContext)"
                    maxlength="14"
                    :disabled="busy"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: displayMenu -->
            <b-col
              cols="2"
            >
              <b-form-group
                :label="`${$t('Display Menu by Default')}`"
                label-for="header-display-menu"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Display Menu by Default')"
                  rules="required"
                >
                  <b-form-radio
                    v-model="headerData.displayMenu"
                    name="header-display-menu"
                    :value="true"
                    class="custom-control-success cursor-pointer"
                    inline
                  >
                    <span class="cursor-pointer">{{ $t('Shown') }}</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="headerData.displayMenu"
                    name="header-display-menu"
                    :value="false"
                    class="custom-control-danger cursor-pointer"
                    inline
                  >
                    <span class="cursor-pointer">{{ $t('Collapsed') }}</span>
                  </b-form-radio>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

          </b-row>
          <hr>
          <b-card-title class="mb-1">
            {{ $t('Template Rich Menu') }}
          </b-card-title>
          <b-row>
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <b-row
                id="richmenu_image"
                ref="richmenuImage"
                class="ml-0 mr-0"
              >
                <b-col>
                  <div>
                    <b-row
                      v-for="(layouts, index) in templateSelect.layout"
                      :key="index"
                    >
                      <b-col
                        v-for="(col, cc) in layouts.cols"
                        :key="cc"
                        :class="checkTitle(col)"
                        :cols="col.gridCols"
                        style="cursor: pointer;"
                        @click="selectColumn(col)"
                      >
                        <div v-if="col.title">
                          {{ col.title }}
                        </div>
                        <div
                          v-if="col.rows"
                        >
                          <b-row
                            v-for="(row, rr) in col.rows"
                            :key="rr"
                          >
                            <b-col
                              :cols="row.gridCols"
                              :class="row.class"
                              @click="selectColumn(row)"
                            >
                              {{ row.title }}
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <b-button
                    variant="primary"
                    block
                    @click="selectTemplate"
                  >
                    {{ $t('Select Template') }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mt-1 mb-1">
                <b-col>
                  <b-button
                    variant="info"
                    block
                    @click="uploadBackgroundImage"
                  >
                    {{ $t('Upload Background Image') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="8"
            >
              <rich-menu-action-form
                v-for="(data, index) in bodyData"
                :key="index"
                :local-data="data"
                :index="index"
              />
            </b-col>
          </b-row>

          <b-card-footer class="p-0 m-0 pt-1">
            <b-row
              class="text-center"
              align="center"
            >
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  right
                  :disabled="busy"
                  @click="clickCancel"
                >
                  {{ $t('Cancel') }}
                </b-button>
                <b-button
                  ref="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="ml-1"
                  right
                  :disabled="busy"
                  @click="onSubmit"
                >
                  {{ $t('Save Data') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>

          <b-overlay
            :show="busy"
            no-wrap
            @shown="onShown"
            @hidden="onHidden"
          >
            <template #overlay>
              <div
                v-if="processing"
                class="text-center p-2 bg-primary text-light rounded"
              >
                <feather-icon
                  icon="UploadCloudIcon"
                  size="20"
                />
                <div class="mb-2">
                  {{ `${$t('Processing')}...` }}
                </div>
                <b-progress
                  min="1"
                  max="10"
                  :value="counter"
                  variant="success"
                  height="6px"
                  class="mx-n1"
                />
              </div>
              <div
                v-else
                ref="dialog"
                tabindex="-1"
                role="dialog"
                aria-modal="false"
                aria-labelledby="form-confirm-label"
                class="text-center p-3"
              >
                <b-card-text
                  class="font-weight-bolder"
                >
                  {{ $t('Are you sure?') }}
                </b-card-text>
                <div class="d-flex">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mr-3"
                    @click="onCancel"
                  >
                    {{ $t('Cancel') }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="outline-success"
                    @click="onOK"
                  >
                    {{ $t('Ok') }}
                  </b-button>
                </div>
              </div>
            </template>
          </b-overlay>

        </b-card>
      </b-form>
    </validation-observer>

    <rich-menu-select-template
      v-model="showModelSelectTemplate"
      :title="$t('Select Template')"
      :template-rich-menu="templateRichMenu"
      @update-select-template="updateSelectTemplate"
      @discard-select-template="discardSelectTemplate"
    />

    <upload-background-image
      v-model="showModelUploadBackgroundImage"
      :title="$t('Upload Background Image')"
      :file-image="fileImage"
      @update-upload-background-image="updateUploadBackgroundImage"
      @update-upload-background-file="updateUploadBackgroundFile"
      @discard-upload-background-image="discardUploadBackgroundImage"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardFooter,
  BCardText,
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BOverlay,
  BProgress,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import RichMenuActionForm from './RichMenuActionForm.vue'
import RichMenuSelectTemplate from './RichMenuSelectTemplate.vue'
import UploadBackgroundImage from './UploadBackgroundImage.vue'
import richMenuTemplateStoreModule from './richMenuTemplateStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardTitle,
    BCardFooter,
    BCardText,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BOverlay,
    BProgress,

    ValidationProvider,
    ValidationObserver,

    RichMenuActionForm,
    RichMenuSelectTemplate,
    UploadBackgroundImage,
  },
  data() {
    return {
      showModelSelectTemplate: false,
      showModelUploadBackgroundImage: false,
      fileImage: null,
      headerData: {
        name: '',
        type: 'default',
        chatBarTitle: 'Menu',
        displayMenu: true,
        layoutName: '',
      },
      bodyData: [],
      templateSelect: {
        name: '',
        input: [],
        layout: [],
      },
      templateRichMenu: [
        {
          name: 'small1x1',
          input: ['A'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '12',
                },
              ],
            },
          ],
        },
        {
          name: 'small2x1',
          input: ['A', 'B'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '6',
                },
                {
                  title: 'B',
                  gridCols: '6',
                },
              ],
            },
          ],
        },
        {
          name: 'small2lx1',
          input: ['A', 'B'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '8',
                },
                {
                  title: 'B',
                  gridCols: '4',
                },
              ],
            },
          ],
        },
        {
          name: 'small2rx1',
          input: ['A', 'B'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '4',
                },
                {
                  title: 'B',
                  gridCols: '8',
                },
              ],
            },
          ],
        },
        {
          name: 'small3x1',
          input: ['A', 'B', 'C'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '4',
                },
                {
                  title: 'B',
                  gridCols: '4',
                },
                {
                  title: 'C',
                  gridCols: '4',
                },
              ],
            },
          ],
        },
        {
          name: 'big1x1',
          input: ['A'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '12',
                  class: 'big-center',
                },
              ],
            },
          ],
        },
        {
          name: 'big3x2',
          input: ['A', 'B', 'C', 'D', 'E', 'F'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '4',
                  class: 'small-center',
                },
                {
                  title: 'B',
                  gridCols: '4',
                  class: 'small-center',
                },
                {
                  title: 'C',
                  gridCols: '4',
                  class: 'small-center',
                },
              ],
            },
            {
              cols: [
                {
                  title: 'D',
                  gridCols: '4',
                  class: 'small-center',
                },
                {
                  title: 'E',
                  gridCols: '4',
                  class: 'small-center',
                },
                {
                  title: 'F',
                  gridCols: '4',
                  class: 'small-center',
                },
              ],
            },
          ],
        },
        {
          name: 'big2x2',
          input: ['A', 'B', 'C', 'D'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '6',
                  class: 'small-center',
                },
                {
                  title: 'B',
                  gridCols: '6',
                  class: 'small-center',
                },
              ],
            },
            {
              cols: [
                {
                  title: 'C',
                  gridCols: '6',
                  class: 'small-center',
                },
                {
                  title: 'D',
                  gridCols: '6',
                  class: 'small-center',
                },
              ],
            },
          ],
        },
        {
          name: 'big1u3dx2',
          input: ['A', 'B', 'C', 'D'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '12',
                  class: 'small-center',
                },
              ],
            },
            {
              cols: [
                {
                  title: 'B',
                  gridCols: '4',
                  class: 'small-center',
                },
                {
                  title: 'C',
                  gridCols: '4',
                  class: 'small-center',
                },
                {
                  title: 'D',
                  gridCols: '4',
                  class: 'small-center',
                },
              ],
            },
          ],
        },
        {
          name: 'big2x2r',
          input: ['A', 'B', 'C'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '8',
                  class: 'big-center',
                },
                {
                  gridCols: '4',
                  rows: [
                    {
                      title: 'B',
                      gridCols: '12',
                      class: 'small-center',
                    },
                    {
                      title: 'C',
                      gridCols: '12',
                      class: 'small-center',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'big1x2',
          input: ['A', 'B'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '12',
                  class: 'small-center',
                },
              ],
            },
            {
              cols: [
                {
                  title: 'B',
                  gridCols: '12',
                  class: 'small-center',
                },
              ],
            },
          ],
        },
        {
          name: 'big2x1',
          input: ['A', 'B'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '6',
                  class: 'big-center',
                },
                {
                  title: 'B',
                  gridCols: '6',
                  class: 'big-center',
                },
              ],
            },
          ],
        },
        {
          name: 'big2u1dx2',
          input: ['A', 'B', 'C'],
          layout: [
            {
              cols: [
                {
                  title: 'A',
                  gridCols: '6',
                  class: 'small-center',
                },
                {
                  title: 'B',
                  gridCols: '6',
                  class: 'small-center',
                },
              ],
            },
            {
              cols: [
                {
                  title: 'C',
                  gridCols: '12',
                  class: 'small-center',
                },
              ],
            },
          ],
        },
      ],
      imageFile: null,
      richMenuTemplateData: [],
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  mounted() {
    this.getRichMenuTemplate()
  },
  methods: {
    getRichMenuTemplate() {
      store
        .dispatch('richmenu-template-store/getRichMenuTemplate',
          {
            language: this.$i18n.locale,
            lineOaId: this.lineOaId,
            id: router.currentRoute.params.id,
          })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.richMenuTemplateData = response.data
          let layoutName = this.richMenuTemplateData.layout_name
          if (layoutName === '' || layoutName === undefined || layoutName === null) {
            layoutName = 'small1x1'
          }
          const index = this.templateRichMenu.findIndex(t => (t.name === layoutName))
          this.templateSelect = this.templateRichMenu[index]
          const displayMenu = (this.richMenuTemplateData.display_menu === '1')
          this.headerData = {
            name: this.richMenuTemplateData.name,
            type: this.richMenuTemplateData.type,
            chatBarTitle: this.richMenuTemplateData.chat_bar_title,
            displayMenu,
            layoutName,
          }
          this.bodyData = []
          const detailData = this.richMenuTemplateData.detail
          this.templateSelect.input.forEach(title => {
            const input = {
              title,
              collapsed: false,
              type: '',
              lineLiffOptions: this.richMenuTemplateData.lineLiffOptions,
              text: {
                text: '',
                maxCount: 50,
              },
              link: {
                url: '',
                label: '',
              },
              liff: {
                url: '',
                label: '',
              },
              postback: {
                text: '',
                data: '',
              },
              dialogue: {
                text: '',
                id: '',
                name: '',
                data: '',
              },
              not_set: '',
            }
            const indexDetail = detailData.findIndex(t => (t.title === title))
            if (indexDetail >= 0) {
              const detail = detailData[indexDetail]
              if (detail.type !== '' && detail.type !== undefined && detail.type !== null) {
                input.type = detail.type
                if (detail.type === 'text') {
                  input.text.text = (detail.value.text) ? detail.value.text : ''
                }
                if (detail.type === 'link') {
                  input.link.url = (detail.value.url) ? detail.value.url : ''
                  input.link.label = (detail.value.label) ? detail.value.label : ''
                }
                if (detail.type === 'liff') {
                  input.liff.url = (detail.value.url) ? detail.value.url : ''
                  input.liff.label = (detail.value.label) ? detail.value.label : ''
                }
                if (detail.type === 'postback') {
                  input.postback.text = (detail.value.text) ? detail.value.text : ''
                  input.postback.data = (detail.value.data) ? detail.value.data : ''
                }
                if (detail.type === 'dialogue') {
                  input.dialogue.text = (detail.value.text) ? detail.value.text : ''
                  input.dialogue.id = (detail.value.id) ? detail.value.id : ''
                  input.dialogue.name = (detail.value.name) ? detail.value.name : ''
                  input.dialogue.data = (detail.value.data) ? detail.value.data : ''
                }
              }
            }
            this.bodyData.push(input)
          })

          this.$refs.richmenuImage.style.color = '#000000'
          this.$refs.richmenuImage.style.backgroundColor = '#eeeeeee'
          this.imageFile = null
          const src = this.richMenuTemplateData.image_url
          if (src) {
            this.$refs.richmenuImage.style.color = '#ffffff'
            this.$refs.richmenuImage.style.background = `url('${src}') center / cover no-repeat`
          }
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    checkTitle(col) {
      if (col.title) {
        if (col.class) {
          return col.class
        }
        return 'small-center'
      }
      return ''
    },
    selectTemplate() {
      this.showModelSelectTemplate = true
    },
    updateSelectTemplate(val) {
      const index = this.templateRichMenu.findIndex(t => (t.name === val))
      this.templateSelect = this.templateRichMenu[index]
      this.headerData.layoutName = this.templateSelect.name
      this.bodyData = []
      this.templateSelect.input.forEach(title => {
        const input = {
          title,
          collapsed: false,
          type: '',
          lineLiffOptions: this.richMenuTemplateData.lineLiffOptions,
          text: {
            text: '',
            maxCount: 50,
          },
          link: {
            url: '',
            label: '',
          },
          liff: {
            url: '',
            label: '',
          },
          postback: {
            text: '',
            data: '',
          },
          dialogue: {
            text: '',
            id: '',
            name: '',
            data: '',
          },
          not_set: '',
        }
        this.bodyData.push(input)
      })
    },
    discardSelectTemplate() {
    },
    selectColumn(col) {
      if (col.title !== undefined) {
        // console.log(col.title)
        const index = this.bodyData.findIndex(ld => (ld.title === col.title))
        // console.log(this.bodyData[index].collapsed)
        this.$set(this.bodyData[index], 'collapsed', !this.bodyData[index].collapsed)
      }
    },
    uploadBackgroundImage() {
      this.showModelUploadBackgroundImage = true
    },
    selectImage() {
      this.$refs.fileInput.click()
    },
    updateUploadBackgroundImage(val) {
      const { src } = val

      this.$refs.richmenuImage.style.color = '#ffffff'
      // this.$refs.richmenuImage.style.background = "url('https://upload.wikimedia.org/wikipedia/commons/c/ca/The_Sky_Garden.jpg') center / cover no-repeat"
      this.$refs.richmenuImage.style.background = `url('${src}') center / cover no-repeat`
    },
    updateUploadBackgroundFile(file) {
      this.imageFile = file
    },
    discardUploadBackgroundImage() {
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      store.dispatch('richmenu-template-store/editRichMenuTemplate',
        {
          id: router.currentRoute.params.id,
          lineOaId: this.lineOaId,
          template: this.headerData,
          detail: this.bodyData,
        })
        .then(() => {
          if (this.imageFile !== null) {
            this.updateImage(router.currentRoute.params.id)
          } else {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false
            this.$swal({
              icon: 'success',
              title: this.$i18n.t('Updated'),
              text: this.$i18n.t('Your data has been updated'),
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.$router.push({ name: 'lineapi-richmenu-list' })
          }
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    updateImage(id) {
      const formData = new FormData()
      formData.append('file', this.imageFile)

      store.dispatch('richmenu-template-store/uploadImageRichMenuTemplate', { id, data: formData })
        .then(() => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false
          this.imageFile = null

          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Updated'),
            text: this.$i18n.t('Your data has been updated'),
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$router.push({ name: 'lineapi-richmenu-list' })
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    clickCancel() {
      this.$router.push({ name: 'lineapi-richmenu-list' })
    },
  },
  setup() {
    if (!store.hasModule('richmenu-template-store')) store.registerModule('richmenu-template-store', richMenuTemplateStoreModule)
    onUnmounted(() => {
      if (store.hasModule('richmenu-template-store')) store.unregisterModule('richmenu-template-store')
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style>
  .small-center {
    font-size: 20px;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    height: 140px;
    border: 1px solid black !important;
  }
  .big-center {
    font-size: 20px;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    height: 280px;
    border: 1px solid black !important;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>
