<template>
  <div>
    <b-card
      no-body
      :aria-expanded="!content_visible ? 'true' : 'false'"
      :aria-controls="parentID"
      style="border: 1px solid #eeeeee; margin-bottom: 10px; cursor: pointer;"
      v-on="$listeners"
    >
      <div
        v-if="localData.title"
        class="card-header"
      >
        <!-- Title -->
        <div style="width: 95%;">
          <b-card-title
            @click="triggerCollapse"
          >{{ localData.title }}</b-card-title>
        </div>

        <div class="heading-elements">
          <ul class="list-inline mb-0">
            <li>
              <a
                data-action="collapse"
                :class="{ rotate: !content_visible }"
                @click="triggerCollapse"
              >
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Collapsible Content -->
      <b-collapse
        :id="parentID"
        v-model="content_visible"
        :visible="content_visible"
        class="card-content"
      >
        <b-card-body>
          <b-row>
            <!-- Type -->
            <b-col
              cols="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                :label="$t('Type')"
                :label-for="`type-${index}`"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Type')"
                  rules="required"
                >
                  <v-select
                    :id="`type-${index}`"
                    v-model="localData.type"
                    :options="typeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    :input-id="`type-${index}`"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="selectType == 'text'">
            <!-- Text -->
            <b-col
              cols="12"
              class="mb-md-0 mb-2 mt-1"
            >
              <b-form-group
                :label="$t('Enter any chat keywords or other text here (50 characters or less)')"
                :label-for="`text-text-${index}`"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Text')"
                  rules="required"
                >
                  <b-form-textarea
                    :id="`text-text-${index}`"
                    v-model="localData.text.text"
                    :placeholder="$t('Enter Text')"
                    rows="3"
                    :maxlength="localData.text.maxCount"
                    :state="localData.text.text.length <= localData.text.maxCount && getValidationState(validationContext)"
                    :class="localData.text.text.length > localData.text.maxCount ? 'text-danger' : ''"
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="localData.text.text.length > localData.text.maxCount ? 'bg-danger' : ''"
                  >
                    <span class="char-count">{{ localData.text.text.length }}</span> / {{ localData.text.maxCount }}
                  </small>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="selectType == 'link'">
            <!-- URL,Label -->
            <b-col
              cols="12"
              class="mb-md-0 mb-2"
            >
              <b-row>
                <!-- URL -->
                <b-col
                  cols="12"
                  class="mb-md-0 mb-2 mt-1"
                >
                  <b-form-group
                    :label="$t('URL')"
                    :label-for="`link-label-${index}`"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('URL')"
                      rules="required"
                    >
                      <b-form-input
                        :id="`link-label-${index}`"
                        v-model="localData.link.url"
                        :placeholder="$t('URL')"
                        :state="getValidationState(validationContext)"
                      />
                      <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Label -->
                <b-col
                  cols="12"
                  class="mb-md-0 mb-2"
                >
                  <b-form-group
                    :label="$t('Action label (20 characters or less)')"
                    :label-for="`link-label-${index}`"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('Enter a description of this action')"
                    >
                      <b-form-textarea
                        :id="`link-label-${index}`"
                        v-model="localData.link.label"
                        :placeholder="$t('Enter a description of this action')"
                        rows="3"
                        maxlength="20"
                        :state="localData.link.label.length <= 20 && getValidationState(validationContext)"
                        :class="localData.link.label.length > 20 ? 'text-danger' : ''"
                      />
                      <small
                        class="textarea-counter-value float-right"
                        :class="localData.link.label.length > 20 ? 'bg-danger' : ''"
                      >
                        <span class="char-count">{{ localData.link.label.length }}</span> / {{ 20 }}
                      </small>
                      <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="selectType == 'liff'">
            <!-- URL,Label -->
            <b-col
              cols="12"
              class="mb-md-0 mb-2"
            >
              <b-row>
                <!-- URL -->
                <b-col
                  cols="12"
                  class="mb-md-0 mb-2 mt-1"
                >
                  <b-form-group
                    :label="$t('URL')"
                    :label-for="`liff-select-${index}`"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('URL')"
                      rules="required"
                    >
                      <v-select
                        :id="`liff-select-${index}`"
                        v-model="localData.liff.url"
                        :options="localData.lineLiffOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="`liff-url-${index}`"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-input
                        :id="`liff-url-${index}`"
                        v-model="localData.liff.url"
                        :placeholder="$t('URL')"
                        readonly
                        style="margin-top: 0.4rem;"
                      />
                      <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Label -->
                <b-col
                  cols="12"
                  class="mb-md-0 mb-2"
                >
                  <b-form-group
                    :label="$t('Action label (20 characters or less)')"
                    :label-for="`liff-label-${index}`"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('Enter a description of this action')"
                    >
                      <b-form-textarea
                        :id="`liff-label-${index}`"
                        v-model="localData.liff.label"
                        :placeholder="$t('Enter a description of this action')"
                        rows="3"
                        class="char-textarea"
                        maxlength="20"
                        :state="localData.liff.label.length <= 20 && getValidationState(validationContext)"
                        :class="localData.liff.label.length > 20 ? 'text-danger' : ''"
                      />
                      <small
                        class="textarea-counter-value float-right"
                        :class="localData.liff.label.length > 20 ? 'bg-danger' : ''"
                      >
                        <span class="char-count">{{ localData.liff.label.length }}</span> / {{ 20 }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="selectType == 'postback'">
            <!-- Text,PostbackData -->
            <b-col
              cols="12"
              class="mb-md-0 mb-2"
            >
              <b-row>
                <!-- Text -->
                <b-col
                  cols="12"
                  class="mb-md-0 mb-2"
                >
                  <b-form-group
                    :label="$t('Action text (20 characters or less)')"
                    :label-for="`postback-text-${index}`"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('Enter a description of this action')"
                    >
                      <b-form-textarea
                        :id="`postback-text-${index}`"
                        v-model="localData.postback.text"
                        :placeholder="$t('Enter a description of this action')"
                        rows="1"
                        maxlength="20"
                        :state="localData.postback.text.length <= 20 && getValidationState(validationContext)"
                        :class="localData.postback.text.length > 20 ? 'text-danger' : ''"
                      />
                      <small
                        class="textarea-counter-value float-right"
                        :class="localData.postback.text.length > 20 ? 'bg-danger' : ''"
                      >
                        <span class="char-count">{{ localData.postback.text.length }}</span> / {{ 20 }}
                      </small>
                      <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Postback Data -->
                <b-col
                  cols="12"
                  class="mb-md-0 mb-2 mt-1"
                >
                  <b-form-group
                    :label="$t('Postback Data')"
                    :label-for="`postback-data-${index}`"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('Postback Data')"
                      rules="required"
                    >
                      <b-form-input
                        :id="`postback-data-${index}`"
                        v-model="localData.postback.data"
                        :placeholder="$t('Postback Data')"
                        :state="getValidationState(validationContext)"
                      />
                      <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="selectType == 'dialogue'">
            <!-- Text,Dialogue(postback) -->
            <b-col
              cols="12"
              class="mb-md-0 mb-2"
            >
              <b-row>
                <!-- Text -->
                <b-col
                  cols="12"
                  class="mb-md-0 mb-2"
                >
                  <b-form-group
                    :label="$t('Action text (20 characters or less)')"
                    :label-for="`dialogue-text-${index}`"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('Enter a description of this action')"
                    >
                      <b-form-textarea
                        :id="`dialogue-text-${index}`"
                        v-model="localData.dialogue.text"
                        :placeholder="$t('Enter a description of this action')"
                        rows="1"
                        maxlength="20"
                        :state="localData.dialogue.text.length <= 20 && getValidationState(validationContext)"
                        :class="localData.dialogue.text.length > 20 ? 'text-danger' : ''"
                      />
                      <small
                        class="textarea-counter-value float-right"
                        :class="localData.dialogue.text.length > 20 ? 'bg-danger' : ''"
                      >
                        <span class="char-count">{{ localData.dialogue.text.length }}</span> / {{ 20 }}
                      </small>
                      <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Dialogue -->
                <b-col
                  cols="12"
                  class="mb-md-0 mb-2 mt-1"
                >
                  <b-form-group
                    :label="$t('Dialogue')"
                    :label-for="`dialogue-select-${index}`"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('Dialogue')"
                      rules="required"
                    >
                      <b-form-input
                        v-model="localData.dialogue.id"
                        style="display: none;"
                        :state="getValidationState(validationContext)"
                      />
                      <b-input-group class="m-0">
                        <b-input-group-prepend
                          is-text
                          style="cursor: pointer;"
                          @click="selectDialogue"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            size="12"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`dialogue-select-${index}`"
                          v-model="localData.dialogue.name"
                          :disabled="true"
                          style="background: #f8f8f8 !important;"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>

                  <b-form-group
                    :label="$t('Postback Data')"
                    :label-for="`dialogue-data-${index}`"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('Postback Data')"
                      rules="required"
                    >
                      <b-form-input
                        :id="`dialogue-data-${index}`"
                        v-model="localData.dialogue.data"
                        :placeholder="$t('Postback Data')"
                        :state="getValidationState(validationContext)"
                        readonly
                      />
                      <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- Dialogue Select -->
    <dialogue-select
      v-model="showDialogueSelectModal"
      :title="`${$t('Select')} ${$t('Dialogue')}`"
      :dialogue-data="dialogueData"
      @update-dialogue-select="updateDialogueSelect"
      @discard-dialogue-select="discardDialogueSelect"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BCollapse,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'
import {
  ValidationProvider,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import DialogueSelect from '@/views/lineapi/dialogue/DialogueSelect.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BCollapse,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,

    FeatherIcon,
    vSelect,
    DialogueSelect,

    ValidationProvider,
  },
  inheritAttrs: false,
  props: {
    localData: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      value: '',
      parentID: '',
      typeOptions: [
        {
          label: this.$i18n.t('Text'),
          value: 'text',
        },
        {
          label: this.$i18n.t('Link'),
          value: 'link',
        },
        {
          label: this.$i18n.t('Liff'),
          value: 'liff',
        },
        {
          label: this.$i18n.t('Postback'),
          value: 'postback',
        },
        {
          label: this.$i18n.t('Dialogue'),
          value: 'dialogue',
        },
        {
          label: this.$i18n.t('Not Set'),
          value: 'not_set',
        },
      ],
      showDialogueSelectModal: false,
      dialogueData: {},
    }
  },
  computed: {
    selectType: {
      // getter
      get() {
        return this.localData.type
      },
      // setter
      set() {
      },
    },
    content_visible: {
      // getter
      get() {
        return this.localData.collapsed
      },
      // setter
      set() {
      },
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'localData.type': function (newValue) {
      this.selectType = newValue
    },
  },
  created() {
    this.parentID = String(Math.floor(Math.random() * 10) + 1)
  },
  methods: {
    triggerCollapse() {
      this.localData.collapsed = !this.localData.collapsed
      // this.content_visible = !this.content_visible
      this.$emit('collapse')
    },
    updateDialogueSelect(data) {
      this.$nextTick(() => {
        this.localData.dialogue.id = data.select.id
        this.localData.dialogue.name = data.select.name
        this.localData.dialogue.data = data.select.postback
        this.dialogueData.id = this.localData.dialogue.id
        this.dialogueData.name = this.localData.dialogue.name
        this.dialogueData.data = this.localData.dialogue.data
      })
    },
    discardDialogueSelect() {
    },
    selectDialogue() {
      if (this.localData.dialogue.id === '') {
        this.dialogueData = {}
      } else {
        this.dialogueData = {}
        this.dialogueData.id = this.localData.dialogue.id
        this.dialogueData.name = this.localData.dialogue.name
        this.dialogueData.data = this.localData.dialogue.data
      }
      this.showDialogueSelectModal = true
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>
